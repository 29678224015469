html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
}

#root {
  display: flex;
}

.str-chat__channel-list {
  width: 100%;
}

.str-chat__channel {
  width: 100%;
}

.str-chat__thread {
  width: 100%;
}

.channel-preview {
  display: flex;
  gap: 16px;
  align-items: center;
  font-size: 0.9em;
  line-height: 1.2em;
}

.channel-preview__avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
}

.channel-preview__main {
  flex-grow: 1;
}

.channel-preview__header {
  font-weight: bold;
  margin-bottom: 4px;
}

.str-chat__date-separator {
  display: none;
}

.str-chat__list .str-chat__message-list-scroll .str-chat__li {
  padding-top: 2rem;
}

.channel-preview__message {
  max-height: 2.4em;
  overflow: hidden;
  color: #858688;
}

.channel-preview__message p {
  margin: 0;
}

.str-chat__message-input
  .str-chat__message-input-inner
  .str-chat__file-input-container {
  display: none;
}
