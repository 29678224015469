.header-title {
  padding-left: 3rem;
  padding-top: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
}

.header-pound {
  color: #006cff;
  font-weight: 800;
  padding-right: 2px;
  text-transform: capitalize;
}
